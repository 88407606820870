import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const schema = yup.object({
  email: yup.string().email().required("Email is required"),
});

const Subscribe = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    navigate("/signup", {state: {email: data.email}})
  };
  return (
    <form className="flex gap-2 items-start" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <input
          type="email"
          placeholder="Enter your email"
          className="flex-1 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900"
          {...register("email")}
        />
        {errors?.email && (
          <div className="text-red-600 text-xs mt-0.5">
            {errors.email.message}
          </div>
        )}
      </div>

      <button
        type="submit"
        className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Subscribe
      </button>
    </form>
  );
};

export default Subscribe;
