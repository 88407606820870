import axios from "axios";
import React, { useEffect, useState } from "react";
import { PATRIKA } from "../Constants";
import Loader from "./Loader";
import PdfLink from "./PdfLink";

const PatrikaList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [patrikas, setPatrikas] = useState([]);
  const fetchPatrikas = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(PATRIKA);
      setPatrikas(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchPatrikas();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-12">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {patrikas.length <= 0 ? (
        <div className="mt-12 text-center w-full">No Issues Found.</div>
      ) : (
        <div className="mx-auto grid max-w-5xl grid-cols-2 gap-6 py-12 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
          {patrikas.map((patrika) => {
            return (
              <PdfLink patrika={patrika} />
              // name={pdf.name} pdfSrc={pdf.pdfSrc} imgSrc={pdf.imgSrc}
            );
          })}
        </div>
      )}
    </>
  );
};

export default PatrikaList;
