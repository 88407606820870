import axios from "axios";
import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useLocation } from "react-router-dom";
import Loader from "../Component/Loader";
import Header from "../Component/Header";

import { GET_PATRIKA } from "../Constants";

const PdfViewer = () => {
  const location = useLocation();
  const [patrika, setPatrika] = useState();
  const pdfUrl = new URLSearchParams(location.search).get("pdfUrl");
  const id = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    if (id) {
      const fetch = async () => {
        const response = await axios.get(`${GET_PATRIKA}/${id}`);
        if (response.data) {
          setPatrika(response.data);
        }
      }

      fetch();
    } else {
      // navigate('/');
    }
  }, [id]);


  const [numPages, setNumPages] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  if (!patrika) {
    <div><Loader /></div>
  }
  if (patrika && patrika.patrikaFile?.length > 0) {
    return (
      <div className="flex min-h-screen flex-col">
        <Header />
        <main className="flex-1 overflow-auto">
          <div className="flex justify-center m-6">
            <iframe title={"Patrika"} src={patrika.patrikaFile[0].downloadUrl} width="100%" height="100%" style={{ width: "100vw", height: "100vh" }}></iframe>
            {/* <Document
              file={patrika.patrikaFile[0].downloadUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-document"
              loading={<Loader />}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  loading="loading..."
                />
              ))}
            </Document> */}
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col">
      <Header />
      <main className="flex-1 overflow-auto">
        <div className="flex justify-center m-6">
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf-document"
            loading={<Loader />}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={index}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                loading="loading..."
              />
            ))}
          </Document>
        </div>
      </main>
    </div>
  );
};

export default PdfViewer;
