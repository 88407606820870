import React from "react";

const PdfLink = ({ patrika }) => {
  const openPdfInNewTab = () => {
    const url = new URL("/pdf-viewer", window.location.origin);
    url.searchParams.append("pdfUrl", "/patrika.pdf");
    url.searchParams.append("id", patrika.id);
    window.open(url, "_blank");
  };
  return (
    <div
      onClick={openPdfInNewTab}
      className="group flex flex-col h-auto cursor-pointer items-start justify-start gap-1 rounded-md bg-background p-4 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50"
    >
      <img
        src={patrika?.thumbnail?.length > 0 ? patrika?.thumbnail[0]?.publicUrl || '/images/logo.jpg' : '/images/logo.jpg'}
        width="150"
        height="200"
        alt="Past Issue"
        className="mx-auto aspect-[3/4] overflow-hidden rounded-md object-cover object-center"
      />
      {patrika?.label && <div className="text-lg font-medium leading-none group-hover:underline font-bold">
        {patrika.label}
      </div>}
      {patrika?.publishDate && <div className="text-sm font-medium leading-none group-hover:underline font-semibold">
        Published - {patrika.publishDate}
      </div>}
    </div>
  );
};

export default PdfLink;
