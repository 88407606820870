import React from "react";

const Loader = () => {
  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center">
      <div className="w-9 h-9 border-8 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-indigo-600 rounded-full">
      </div>
    </div>
  );
};

export default Loader;
