import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { BUY_SUBSCRIPTION } from "../Constants";

const BuySubscription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const customerId = localStorage.getItem("customerId");

  const handleBuySubscription = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BUY_SUBSCRIPTION}/${customerId}`);
      if(response.data){
        if(response.data.receipt?.short_url){
          window.open(response.data.receipt?.short_url,"_blank")
        }
      localStorage.setItem("customer", JSON.stringify(response.data));
      setTimeout(()=>{window.location.reload();},2000)  
      
      }
    } catch (error) {
      toast.error("An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <button
      className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      onClick={handleBuySubscription}
    >
      {isLoading ? <Loader /> : "Subscribe Now"}
    </button>
  );
};

export default BuySubscription;
